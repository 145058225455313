.experience-comp {
  width: -wekit-fill-available;
  height: auto;
  padding: 2em 10em;
}
.recap-comp {
  width: 100%;
  display: flex;
  justify-content: space-around;
  opacity: 0;
  animation: slideLeftFull 0.4s forwards;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
.left-recap-comp {
  width: 45%;
  height: 100%;
  border-radius: 50%;
}
.right-recap-comp {
  width: 45%;
  margin: auto 0 auto auto;
}
.circle-code {
  align-items: center;
  width: 90%;
  padding: auto;
  overflow: hidden;
  height: 100%;
  border-radius: 50%;
  display: flex;
  position: relative;
  aspect-ratio: 1/1;
}
.image-circle {
  position: absolute;
  width: 70%;
  left: 15%;
  bottom: -3%;
  height: auto;
  z-index: 2;
  object-fit: cover;
  object-position: center;
}
.image-elm {
  width: 100%;
  height: 100%;
  opacity: 1;
}
.right-recap-comp .desc-txt {
  width: 70%;
  text-overflow: ellipsis;
  display: block;
  margin: 0;
}
.recap-comp .right-recap-comp .btns-component {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  margin: 0;
}
.counter-container {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-bottom: 2em;
}
.counter-inside {
  width: 100%;
}
.counter-container .counter-inside:nth-last-child() .counter-txt {
  width: 100%;
  text-wrap: wrap;
  height: fit-content;
}
.counter-txt {
  text-wrap: wrap;
  width: 95%;
  height: fit-content;
}
@media only screen and (max-width: 1000px) {
  .experience-comp {
    width: -wekit-fill-available;
    height: auto;
    padding: 1em;
  }
  .recap-comp {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    opacity: 1;
    animation: none;
  }
  .circle-code {
    display: block;
    width: 100%;
    margin: auto;
  }
  .left-recap-comp {
    width: 100%;
  }
  .right-recap-comp {
    width: auto;
    margin: auto auto 2em auto;
    padding: 0 2em;
  }
  .right-recap-comp h1 {
    margin: auto auto 12px auto;
    text-align: center;
  }
  .circle-code {
    width: 100%;
  }
  .right-recap-comp .desc-txt {
    width: 100%;
    margin: 0 auto 2em;
    text-align: center;
  }
  .counter-container {
    display: block;
    width: 100%;
    justify-content: space-around;
    margin-bottom: 2em;
  }
  .counter-txt {
    width: 70%;
    text-align: center;
    margin: auto;
  }
  .recap-comp .right-recap-comp .btns-component {
    display: flex;
    flex-direction: column-reverse;
    animation: none;
  }
  .recap-comp .btns-component .gradient-btn-primary {
    display: flex;
    margin: auto auto 2em auto;
    width: fit-content;
  }
}
