body {
  margin: 0;
  font-family: "Ubuntu", "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--dark-blue-background);
  overflow-y: scroll;
}

#root {
  min-height: 100vh;
  width: auto;
  color: var(--white-color);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
