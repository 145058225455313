.about-me-comp {
  width: -webkit-fill-available;
  height: auto;
  margin: 1em 0;
  overflow: hidden;
}
.about-top-section {
  position: relative;
  width: -webkit-fill-available;
  display: flex;
  justify-content: space-between;
  padding: 5em 10em;
  z-index: 5;
}
.hand-icon {
  display: inline-block;
  /* animation-name: waveAnimation;
  animation-duration: 2.5s;
  animation-iteration-count: infinite; */
  animation: waveAnimation 2.5s infinite;
}
@keyframes waveAnimation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  }
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.vector-comp {
  width: 100%;
  height: -webkit-fill-available;
  position: absolute;
  z-index: 1;
  top: 25vh;
  overflow: hidden;
}
.image-vector {
  width: -webkit-fill-available;
  height: auto;
}
.box-card-animation {
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  background-color: var(--dark-blue-background);
  z-index: 10;
  transform: translateX(0);
  animation: slide-right 1.3s forwards 0.2s;
}
@keyframes slide-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
@keyframes appear-top-bottom {
  0% {
    opacity: 0;
    transform: translateY(-250px);
    scale: 1;
  }
  50% {
    scale: 1.2;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    scale: 1;
  }
}
.img-elem {
  position: absolute;
  width: 100%;
  height: 100%;
}
@keyframes opacityChange {
  0% {
    opacity: 0;
    z-index: 2;
  }
  100% {
    opacity: 1;
    z-index: 6;
  }
}
@keyframes scale-up {
  0% {
    opacity: 0;
    scale: 0;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
}
.text-profile-comp {
  width: 45%;
  margin: auto 0;
  opacity: 0;
  animation: appear-top-bottom 0.6s forwards 0.2s;
}
.profile-comp {
  width: 45%;
  display: flex;
  justify-content: end;
  opacity: 0;
  animation: scale-up 0.6s forwards 0.6s;
}
.profile-img-comp {
  margin: auto;
  position: relative;
  width: 100%;
  height: max-content;
  border-radius: 50%;
  display: flex;
  aspect-ratio: 1/1;
  overflow: hidden;
  background-image: linear-gradient(
    45deg,
    var(--red-coral-logo-color),
    var(--yellow-logo-color)
  );
}
.profile-img {
  bottom: 0;
  width: 100%;
  height: auto;
  left: 0;
  position: absolute;
  height: auto;
  object-position: center;
  object-fit: cover;
}
.desc-txt {
  width: 70%;
  text-overflow: ellipsis;
}
.btns-component {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 2em 0;
}
.gradient-btn-primary {
  opacity: 0;
  animation: opacityChange 1s forwards 1.3s;
}
.bracket-secondary-btn {
  opacity: 0;
  animation: opacityChange 1s forwards 1.5s;
}
.social-media-comp {
  display: flex;
  justify-content: flex-start;
  text-decoration: none;
}
.social-media-comp a {
  opacity: 0;
}
.social-media-comp svg {
  display: block;
  color: var(--dark-blue-background);
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
  border-radius: 50%;
  background-color: var(--white-color);
}
.social-media-comp a:first-child {
  margin-right: 24px;
  animation: scale-up 0.5s forwards 1.3s;
}
.social-media-comp a:nth-child(2) {
  animation: scale-up 0.4s forwards 1.4s;
}
.about-top-section .follow-title {
  width: 100%;
  text-align: left;
}
@media only screen and (max-width: 1000px) {
  .about-top-section {
    position: relative;
    width: 90%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding: 1em 0;
    margin: auto;
  }
  .about-top-section .follow-title {
    width: 100%;
    text-align: center;
  }
  .text-profile-comp {
    width: auto;
    margin: auto;
    opacity: 0;
    padding: 0 2em;
    animation: appear-top-bottom 0.6s forwards 0.2s;
  }
  .text-profile-comp h1 {
    padding: 2em auto;
    text-align: center;
  }
  .text-profile-comp p {
    margin: auto;
  }
  .profile-comp {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    opacity: 0;
    animation: scale-up 0.6s forwards 0.6s;
    margin-bottom: 2em;
  }
  .profile-img-comp {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    aspect-ratio: 1/1;
    overflow: hidden;
    background-image: linear-gradient(
      45deg,
      var(--red-coral-logo-color),
      var(--yellow-logo-color)
    );
  }
  .profile-img {
    width: 100%;
    height: auto;
    opacity: 1;
  }
  .btns-component {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 2em auto;
  }
  .gradient-btn-primary {
    margin-bottom: 2em;
  }
  .social-media-comp {
    display: flex;
    justify-content: center;
  }
  .text-profile-comp h3 {
    text-align: center;
  }
  .desc-txt {
    width: 100%;
    text-align: center;
  }
}
