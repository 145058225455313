.education-comp {
  width: -webkit-fill-available;
  padding: 2em 10em;
  display: flex;
  justify-content: space-between;
}
.education-comp-container {
  display: flex;
  justify-content: space-between;
  opacity: 0;
  animation: slideLeftFull 0.4s forwards;
}
.education-left {
  width: 40%;
}
.education-right {
  width: 40%;
}
.education-field {
  display: block;
}
.information-comp {
  display: flex;
  justify-content: space-between;
  align-items: end;
}
.information-comp p:first-child {
  width: 50%;
}
.information-comp p:nth-child(2) {
  width: 40%;
  display: flex;
  justify-content: flex-end;
}
.txt-information {
  border-bottom: 1px solid var(--white-color);
}
.headline-txt {
  margin-bottom: 8px;
}
@media only screen and (max-width: 1000px) {
  .education-comp-container {
    display: block;
    opacity: 1;
  }
  .education-comp {
    width: -webkit-fill-available;
    padding: 0em 2em;
    margin: 0 auto;
    display: block;
  }
  .education-left {
    width: 100%;
  }
  .education-left h1 {
    width: 100%;
    text-align: center;
  }
  .education-right {
    width: 100%;
  }
  .education-right h1 {
    width: 100%;
    text-align: center;
  }
}
