.counter-comp {
  display: block;
  width: 100%;
}
@media only screen and (max-width: 1000px) {
  .counter-comp {
    display: block;
    margin: auto;
    text-align: center;
  }
}
