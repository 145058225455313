.nav-bar-comp {
  color: var(--white-color);
  padding: 2em 4em;
  border-bottom: 1px solid var(--font-platinum-color);
  background-image: linear-gradient(
    180deg,
    var(--blue-background),
    var(--dark-blue-background)
  );
  position: sticky;
  top: -0.1px;
  z-index: 40;
}
.menu-comp-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo-comp {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: auto;
  height: auto;
  border-radius: 150px;
  background-image: linear-gradient(
    90deg,
    var(--blue-logo-color),
    var(--red-coral-logo-color),
    var(--yellow-logo-color)
  );
  text-align: center;
  font-size: 2em;
  font-weight: bold;
  cursor: pointer;
  animation: scale-up 0.2s forwards;
}
@keyframes scale-up {
  0% {
    opacity: 0;
    scale: 0;
  }
  90% {
    opacity: 1;
    scale: 1.2;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
}
.menu-list-comp {
  list-style: none;
  display: flex;
}
.menu-list-comp li {
  list-style: none;
  margin: 0 24px;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
}
.link-menu {
  color: var(--white-color);
  text-decoration: none;
}
.link-menu:hover {
  color: var(--font-platinum-color);
}
.menu-footer h3 {
  text-align: right;
}
.follow-title {
  text-align: right;
}
.mob-menu {
  display: none;
}
@media only screen and (max-width: 1000px) {
  .nav-bar-comp {
    padding: 0;
    overflow-x: hidden;
    height: 100px;
  }
  .menu-comp-top {
    display: flex;
    padding: 1em 2em;
    justify-content: space-between;
    align-items: center;
  }
  .logo-comp {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: auto;
    height: auto;
    border-radius: 100%;
    background-image: linear-gradient(
      90deg,
      var(--blue-logo-color),
      var(--red-coral-logo-color),
      var(--yellow-logo-color)
    );
    text-align: center;
    font-size: 2em;
    font-weight: bold;
    cursor: pointer;
    animation: scale-up 0.2s forwards;
  }
  .menu-icon {
    position: relative;
    width: 1.5em;
    height: 1.5em;
  }
  .mob-menu {
    display: block;
    position: fixed;
    background-image: linear-gradient(
      180deg,
      var(--dark-blue-background-opacity),
      var(--blue-background-opacity)
    );
    top: 100px;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 100;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
  }
  .mob-menu li {
    padding: 1em 2em;
    border-top: 1px solid var(--font-platinum-color-opacity);
    border-bottom: 1px solid var(--font-platinum-color-opacity);
  }
  .mounted {
    opacity: 0;
    z-index: 0;
  }
  .menu-close {
    transform: translateX(100%);
  }
  .menu-open {
    transform: translateX(0%);
  }
  .open {
    transform: rotate(0);
    animation: rotateLeft90 0.5s forwards;
  }
  .close {
    transform: rotate(90deg);
    animation: rotateRight90 0.5s forwards;
  }
  .menu-list-comp {
    display: none;
  }
  .mob-menu li:nth-last-child(1) {
    border: none;
  }
  .mob-menu .follow-title {
    text-align: center;
  }
}
